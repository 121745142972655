//
// Widgets.scss
//

.mini-stats-wid {
    .mini-stat-icon {
        overflow: hidden;
        position: relative;
        &:before,
        &:after {
            content: '';
            position: absolute;
            width: 8px;
            height: 54px;
            background-color: rgba($white, 0.1);
            left: 16px;
            transform: rotate(32deg);
            top: -5px;
            transition: all 0.4s;
        }

        &::after {
            left: -12px;
            width: 12px;
            transition: all 0.2s;
        }
    }

    &:hover {
        .mini-stat-icon {
            &::after {
                left: 60px;
            }
        }
    }
}
