.input-group-copied {
    position: relative;
    display: inline-block;
}
.input-group-text-copied {
    position: absolute;
    right: 2;
    top: 0;
}

input-copied {
    padding-right: 20px; /* = icon width if you don't want the password to go under the icon */
}
