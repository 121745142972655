//
// Rating
//

.symbol {
    border-color: $card-bg;
}

.rating-symbol-background,
.rating-symbol-foreground {
    font-size: 24px;
}

.rating-symbol-foreground {
    top: 0px;
}

.rating-star {
    > span {
        display: inline-block;
        vertical-align: middle;

        &.badge {
            margin-left: 4px;
        }
    }
}
