/* Timepicker */
.bootstrap-timepicker-widget {
    table {
        td {
            a {
                color: $input-color;
                &:hover {
                    background-color: transparent;
                    border-color: transparent;
                    border-radius: 4px;
                    color: $primary;
                    text-decoration: none;
                }
            }
            input {
                width: 32px;
                height: 32px;
                border: 0;
                color: $body-color;
                border: 1px solid $border-color;
                background-color: $input-bg;
            }
        }
    }

    &.dropdown-menu:after {
        border-bottom-color: $gray-200;
    }
    &.timepicker-orient-bottom:after {
        border-top-color: $gray-200;
    }
}

.timepicker-orient-top {
    top: $input-height !important;
}

.timepicker-orient-bottom {
    top: auto !important;
    bottom: $input-height !important;
}

.bootstrap-timepicker-widget {
    left: 0 !important;
    right: auto !important;
}

.bootstrap-timepicker-widget {
    &.timepicker-orient-left {
        &:before {
            left: 6px;
            right: auto;
        }

        &::after {
            left: 7px;
            right: auto;
        }
    }
}
